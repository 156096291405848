import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import * as React from "react";
import { useEffect, useState } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";

import StandardAlert from "../alerts/StandardAlert";
import { enableSwipe, getItemFromStorage } from "../../utils/utils";

const defaultValues = {
  badge: { badgeId: "Select" },
  isEagle: false,
  duration: "Select",
  startingSession: "Select",
  capacity: "",
  minAge: 10,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  prereqs: null,
  cost: null,
};

export default function AddClassDialog(props) {
  const {
    addCourseIsOpen,
    setAddCourseIsOpen,
    setIsLoading,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    datastore,
  } = props;

  const [formValues, setFormValues] = useState(defaultValues);
  const [allBadges, setAllBadges] = useState([]);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");

  useEffect(() => {
    const fetchBadges = () => {
      return ky
        .get(window._env_.REACT_APP_API_URL + "/badges", {
          timeout: window._env_.REACT_APP_TIMEOUT,
          headers: {
            Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
          },
        })
        .json();
    };
    setIsLoading(true);
    fetchBadges()
      .then((data) => {
        setAllBadges(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setOuterAlertLevel("error");
        setOuterAlertMessage("Error: Could not fetch badges.");
        setOuterAlertIsOpen(true);
        enableSwipe();
        setAddCourseIsOpen(false);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const pn = parsePhoneNumber(value, { regionCode: "US" });
      setFormValues({
        ...formValues,
        [name]: pn.number.national,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleBadgeChange = (badge) => {
    if (badge === "Select") {
      setFormValues({
        ...formValues,
        badge: { badgeId: "Select" },
      });
    } else if (badge === null) {
      // Case where the user deletes the text, do nothing
    } else {
      setFormValues({
        ...formValues,
        badge,
      });
    }
  };

  return (
    <Dialog
      open={addCourseIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setAddCourseIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Add a Class</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setAddCourseIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Please fill out the form below to add a new class to the event.{" "}
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Autocomplete
            options={[{ badgeId: "Select" }, ...allBadges]}
            getOptionLabel={(option) => {
              if (option.badgeId === "Select") return option.badgeId;
              else {
                return `${option.name}`;
              }
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(option, value);
              return option.badgeId === value.badgeId;
            }}
            value={formValues.badge}
            onChange={(event, newValue) => {
              handleBadgeChange(newValue ? newValue : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Course"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    isEagle: e.target.checked,
                  });
                }}
              />
            }
            label="Is Eagle Required?"
          />
          <TextField
            select
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
            required
            value={formValues.duration}
            label="Class Duration (sessions)"
            name="duration"
            onChange={handleInputChange}
          >
            <option value="Select" disabled={true}>
              Select
            </option>
            <option value={1}>1 session</option>
            <option value={2}>2 sessions</option>
            <option value={3}>3 sessions</option>
            <option value={4}>4 sessions</option>
          </TextField>
          <TextField
            select
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
            required
            value={formValues.startingSession}
            label="Starting Session(s)"
            name="startingSession"
            onChange={handleInputChange}
          >
            <option value="Select" disabled={true}>
              Select
            </option>
            <optgroup label="Class starts in:">
              <option
                value="1"
                disabled={["Select"].includes(formValues.duration)}
              >
                Session 1
              </option>
              <option
                value="2"
                disabled={["Select", "4"].includes(formValues.duration)}
              >
                Session 2
              </option>
              <option
                value="3"
                disabled={
                  formValues.duration === "Select" ||
                  ["Select", "3", "4"].includes(formValues.duration)
                }
              >
                Session 3
              </option>
              <option
                value="4"
                disabled={
                  formValues.duration === "Select" ||
                  ["Select", "2", "3", "4"].includes(formValues.duration)
                }
              >
                Session 4
              </option>
            </optgroup>
          </TextField>
          <TextField
            required
            margin="normal"
            fullWidth
            id="capacity-input"
            name="capacity"
            label="Max Capacity (for 1 session)"
            type="number"
            value={formValues.capacity}
            onChange={handleInputChange}
          />
          <TextField
            required
            margin="normal"
            fullWidth
            id="minAge-input"
            name="minAge"
            label="Minimum Age"
            type="number"
            value={formValues.minAge}
            onChange={handleInputChange}
          />
          <Divider sx={{ paddingTop: 1 }} />
          <Typography variant="h6" pt={1}>
            <strong>Optional fields:</strong>
          </Typography>
          <Typography variant="body1">
            The following fields are available to optional until we have the
            appropriate info
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            id="firstName"
            label="Counselor's First Name"
            name="firstName"
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="lastName"
            label="Counselor's Last Name"
            name="lastName"
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Counselor's Email"
            name="email"
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Counselor's Phone Number"
            name="phone"
            value={formValues.phone}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="prereqs"
            label="Prerequisites"
            name="prereqs"
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="cost"
            label="Additional Cost"
            name="cost"
            type="number"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <Button
            onClick={() => {
              if (
                formValues.badge !== "Select" &&
                formValues.occurrences !== "Select" &&
                formValues.duration !== "Select" &&
                formValues.startingSession !== "Select" &&
                formValues.capacity !== ""
              ) {
                setIsLoading(true);
                const finalValues = {
                  meritBadgeName: formValues.badge.name,
                  isEagle: formValues.isEagle,
                  counselorFirstName: formValues.firstName,
                  counselorLastName: formValues.lastName,
                  counselorPhoneNumber: formValues.phone,
                  counselorEmailAddress: formValues.email,
                  classDurationInSessions: parseInt(formValues.duration),
                  startingSession: formValues.startingSession,
                  maxCapacity: parseInt(formValues.capacity),
                  additionalCost: formValues.cost,
                  minAge: parseInt(formValues.minAge) || 10,
                  prereqs: formValues.prereqs,
                  imageUrl: formValues.badge.iconUrl,
                  badgeUrl: formValues.badge.workbookUrl,
                  spotsRemaining: parseInt(formValues.capacity),
                  configId: datastore.config.configId,
                };
                ky.post(window._env_.REACT_APP_API_URL + "/addcourse", {
                  json: finalValues,
                  timeout: window._env_.REACT_APP_TIMEOUT,
                  headers: {
                    Authorization:
                      "Bearer " + getItemFromStorage("mb_jwt_token"),
                  },
                })
                  .json()
                  .then(() => {
                    setIsLoading(false);
                    setOuterAlertLevel("success");
                    setOuterAlertMessage("Course Added!");
                    setOuterAlertIsOpen(true);
                    setFormValues(defaultValues);
                    setAddCourseIsOpen(false);
                    enableSwipe();
                  })
                  .catch((e) => {
                    console.error(e);
                    setAlertLevel("error");
                    setAlertMessage("Unable to add course. Please try again!");
                    setAlertIsOpen(true);
                    setIsLoading(false);
                  });
              } else {
                console.error("e");
                setAlertLevel("error");
                setAlertMessage(
                  "Please make sure all required fields are populated!",
                );
                setAlertIsOpen(true);
              }
            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Course
          </Button>
        </Box>
        <StandardAlert
          alertIsOpen={alertIsOpen}
          setAlertIsOpen={setAlertIsOpen}
          alertLevel={alertLevel}
          alertMessage={alertMessage}
          width="80%"
        />
      </DialogContent>
    </Dialog>
  );
}
