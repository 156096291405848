import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import * as React from "react";
import { useState } from "react";

import StandardAlert from "../alerts/StandardAlert";
import { enableSwipe, getItemFromStorage } from "../../utils/utils";

export default function VerifyDeletionDialog(props) {
  const {
    verifyScoutIsOpen,
    setVerifyScoutIsOpen,
    setIsLoading,
    setTriggerRefresh,
    triggerRefresh,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    setEditScoutIsOpen,
    scout,
    datastore,
  } = props;

  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  return (
    <Dialog
      open={verifyScoutIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setVerifyScoutIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Edit {scout.firstName}'s Info</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setVerifyScoutIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <strong>
            Are you sure you want to remove this scout from your profile?.{" "}
          </strong>
          Doing this will void your registration and you may not be able to get
          a refund for this scout!
        </Typography>
        <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={2}>
          <Button
            variant="contained"
            onClick={() => {
              setVerifyScoutIsOpen(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setIsLoading(true);
              ky.patch(
                window._env_.REACT_APP_API_URL +
                  "/remove-participant/" +
                  datastore.config.configId +
                  "/" +
                  scout.participantId,
                {
                  timeout: window._env_.REACT_APP_TIMEOUT,
                  headers: {
                    Authorization:
                      "Bearer " + getItemFromStorage("mb_jwt_token"),
                  },
                },
              )
                .json()
                .then(() => {
                  setIsLoading(false);
                  setOuterAlertLevel("success");
                  setOuterAlertMessage(
                    "Scout Removed! You may need to refresh to see the changes!",
                  );
                  setOuterAlertIsOpen(true);
                  setTriggerRefresh(!triggerRefresh);
                  setVerifyScoutIsOpen(false);
                  setEditScoutIsOpen(false);
                  enableSwipe();
                })
                .catch((e) => {
                  console.error(e);
                  setOuterAlertLevel("error");
                  setAlertMessage("Unable to remove scout. Please try again!");
                  setAlertIsOpen(true);
                  setIsLoading(false);
                });
            }}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </Stack>
      </DialogContent>
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel="error"
        alertMessage={alertMessage}
        width="80%"
      />
    </Dialog>
  );
}
