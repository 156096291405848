import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { useState } from "react";
import ky from "ky";

import StandardAlert from "../alerts/StandardAlert";
import {
  disableSwipe,
  enableSwipe,
  getItemFromStorage,
} from "../../utils/utils";

import VerifyDeletionDialog from "./VerifyDeletionDialog";

export default function EditScoutDialog(props) {
  const {
    editScoutIsOpen,
    setEditScoutIsOpen,
    scout,
    setIsLoading,
    setTriggerRefresh,
    triggerRefresh,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    datastore,
  } = props;

  const [formValues, setFormValues] = useState({
    firstName: scout.firstName,
    lastName: scout.lastName,
    email: scout.participantEmailAddress,
    phone: scout.phoneNumber,
    unitType: scout.unitType,
    unitNumber: scout.unitNumber,
    gender: scout.gender,
    birthdate: scout.birthdate,
  });
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [verifyScoutIsOpen, setVerifyScoutIsOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Dialog
      open={editScoutIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setEditScoutIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Edit {scout.firstName}'s Info</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setEditScoutIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <strong>
            Please edit the form below with your scout's new information. The
            merit badge counselors will use the information below for
            attendance.{" "}
          </strong>
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Scout's First Name"
            id="firstName"
            name="firstName"
            value={formValues.firstName}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Scout's Last Name"
            id="lastName"
            name="lastName"
            value={formValues.lastName}
            onChange={handleInputChange}
          />
          <TextField
            select
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
            required
            value={formValues.gender}
            label="Scout's Gender"
            name="gender"
            onChange={handleInputChange}
          >
            <option value="Select" disabled={true}>
              Select
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </TextField>
          <TextField
            required
            margin="normal"
            fullWidth
            id="birthdate-input"
            name="birthdate"
            label="Scout's Birthdate"
            type="date"
            value={formValues.birthdate}
            onChange={handleInputChange}
          />
          <TextField
            select
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
            required
            value={formValues.unitType}
            label="Scout's Unit Type"
            name="unitType"
            onChange={handleInputChange}
          >
            <option value="Select" disabled={true}>
              Select
            </option>
            <option value="Troop">Troop</option>
            <option value="Crew">Crew</option>
            <option value="Ship">Ship</option>
          </TextField>
          <TextField
            required
            margin="normal"
            fullWidth
            id="unit-number-input"
            name="unitNumber"
            label="Scout's Unit Number"
            type="number"
            value={formValues.unitNumber}
            onChange={handleInputChange}
          />
          <Divider sx={{ paddingTop: 1 }} />
          <Typography variant="h6" pt={1}>
            <strong>Optional fields:</strong>
          </Typography>
          <Typography variant="body1">
            The following fields are available to optionally send your scout a
            copy of their registration.
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Scout's Email Address"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Scout's Phone Number"
            name="phone"
            value={formValues.phone}
            onChange={handleInputChange}
          />

          <Button
            onClick={() => {
              if (
                formValues.firstName !== "" &&
                formValues.lastName !== "" &&
                formValues.birthdate !== "" &&
                formValues.unitNumber !== "" &&
                formValues.unitType !== "Select" &&
                formValues.gender !== "Select"
              ) {
                setIsLoading(true);
                ky.post(
                  window._env_.REACT_APP_API_URL +
                    "/edit-participant/" +
                    scout.participantId,
                  {
                    json: {
                      userId: getItemFromStorage("mb_user_id"),
                      configId: datastore.config.configId,
                      ...formValues,
                    },
                    timeout: window._env_.REACT_APP_TIMEOUT,
                    headers: {
                      Authorization:
                        "Bearer " + getItemFromStorage("mb_jwt_token"),
                    },
                  },
                )
                  .json()
                  .then(() => {
                    setIsLoading(false);
                    setOuterAlertLevel("success");
                    setOuterAlertMessage(
                      "Scout Modified! You may need to refresh to see the changes!",
                    );
                    setOuterAlertIsOpen(true);
                    setTriggerRefresh(!triggerRefresh);
                    setEditScoutIsOpen(false);
                    enableSwipe();
                  })
                  .catch((e) => {
                    console.error(e);
                    setOuterAlertLevel("error");
                    setAlertMessage(
                      "Unable to modify scout. Please try again!",
                    );
                    setAlertIsOpen(true);
                    setIsLoading(false);
                  });
              } else {
                setOuterAlertLevel("error");
                setAlertMessage(
                  "Please make sure all required fields are populated!",
                );
                setAlertIsOpen(true);
              }
            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Modify Scout
          </Button>
          {scout.initialRegistrationTime ? null : (
            <Button
              onClick={() => {
                setVerifyScoutIsOpen(true);
                disableSwipe();
              }}
              fullWidth
              variant="contained"
              color="error"
              sx={{ mb: 2 }}
            >
              Delete Scout
            </Button>
          )}
        </Box>
        <StandardAlert
          alertIsOpen={alertIsOpen}
          setAlertIsOpen={setAlertIsOpen}
          alertLevel="error"
          alertMessage={alertMessage}
          width="80%"
        />
        <VerifyDeletionDialog
          verifyScoutIsOpen={verifyScoutIsOpen}
          setVerifyScoutIsOpen={setVerifyScoutIsOpen}
          setIsLoading={setIsLoading}
          setTriggerRefresh={setTriggerRefresh}
          triggerRefresh={triggerRefresh}
          setOuterAlertLevel={setOuterAlertLevel}
          setOuterAlertMessage={setOuterAlertMessage}
          setOuterAlertIsOpen={setOuterAlertIsOpen}
          setEditScoutIsOpen={setEditScoutIsOpen}
          scout={scout}
          datastore={datastore}
        />
      </DialogContent>
    </Dialog>
  );
}
