import {
  Backdrop,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import ky from "ky";

import StandardAlert from "../alerts/StandardAlert";
import {
  enableSwipe,
  getItemFromStorage,
  notifyViaSlackOnFailure,
} from "../../utils/utils";

function PayPal(props) {
  const {
    finalPrice,
    setIsLoading,
    scout,
    setAlertLevel,
    setAlertMessage,
    setAlertIsOpen,
    datastore,
  } = props;

  // Function to handle successful payment
  const handlePaymentSuccess = () => {
    setIsLoading(false);
    enableSwipe();
    window.location.reload();
  };

  // Function to handle errors
  const handleError = (error) => {
    notifyViaSlackOnFailure(
      scout.firstName,
      scout.lastName,
      scout.unitType,
      scout.unitNumber,
      "initially register",
      error,
    );
    setIsLoading(false);
    setAlertLevel("error");
    setAlertMessage(
      "Something went wrong. Please contact us and we will help you fix the issue. Do NOT try to pay again please!",
    );
    setAlertIsOpen(true);
    console.error("Error:", error);
    // TODO: Undo DB changes on reg failure...
  };

  // Function to update participant registration with captureId
  const updateRegistrationCapture = (scout, captureId) => {
    return ky.patch(
      window._env_.REACT_APP_API_URL + "/edit-participant-registration-capture",
      {
        json: {
          participantId: scout.participantId,
          configId: datastore.config.configId,
          captureId,
        },
        timeout: window._env_.REACT_APP_TIMEOUT,
        headers: {
          Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
        },
      },
    );
  };

  return (
    <PayPalButtons
      forceReRender={[finalPrice]}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: finalPrice,
              },
            },
          ],
        });
      }}
      onApprove={(data, actions) => {
        setIsLoading(true);
        ky.patch(window._env_.REACT_APP_API_URL + "/re-register-participant", {
          json: {
            configId: datastore.config.configId,
            participantId: scout.participantId,
            orderId: data.orderID,
            paymentMethod: "PayPal",
            paymentAmount: finalPrice.toFixed(2).toString(),
          },
          timeout: window._env_.REACT_APP_TIMEOUT,
          headers: {
            Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
          },
        })
          .then((response) => {
            // NOTE: Uncomment the line below to prevent payments
            // throw new Error("Developer threw error to stop payment");
            if (response.ok) {
              return actions.order.capture();
            } else {
              throw new Error("API call failed");
            }
          })
          .then((captureResponse) => {
            const captureId =
              captureResponse.purchase_units[0].payments.captures[0].id;
            return updateRegistrationCapture(scout, captureId);
          })
          .then(() => handlePaymentSuccess())
          .catch((error) => handleError(error));
      }}
    />
  );
}

export default function RetryPaymentDialog(props) {
  const [{ isPending }] = usePayPalScriptReducer();
  const { isOpen, setIsOpen, scout, datastore } = props;
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>{scout.firstName.trim()}'s Registration</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography variant="body1">
          Your initial attempt to pay for {scout.firstName.trim()}'s
          registration failed.
        </Typography>
        <Typography variant="body1">
          Please reattempt payment below. If you have any questions, please
          contact us.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          <strong>Course Selections:</strong>
        </Typography>
        <Stack spacing={1}>
          {scout.session1BadgeName && (
            <div>
              <Chip label={"Session 1: " + scout.session1BadgeName} />
            </div>
          )}
          {scout.session2BadgeName && (
            <div>
              <Chip label={"Session 2: " + scout.session2BadgeName} />
            </div>
          )}
          {scout.session3BadgeName && (
            <div>
              <Chip label={"Session 3: " + scout.session3BadgeName} />
            </div>
          )}
          {scout.session4BadgeName && (
            <div>
              <Chip label={"Session 4: " + scout.session4BadgeName} />
            </div>
          )}
        </Stack>
        {scout.food && (
          <>
            <Typography variant="h6" mt={1}>
              <strong>Food Selection:</strong>
            </Typography>
            <Typography variant="body1" mb={-2.5}>
              {scout.food}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogContent>
        <Typography variant="h6">
          <strong>Total:</strong> ${parseFloat(scout.paymentAmount)}.00
        </Typography>
      </DialogContent>
      <DialogContent>
        <PayPal
          finalPrice={parseFloat(scout.paymentAmount)}
          scout={scout}
          setIsLoading={setIsLoading}
          setAlertLevel={setAlertLevel}
          setAlertMessage={setAlertMessage}
          setAlertIsOpen={setAlertIsOpen}
          datastore={datastore}
        />
      </DialogContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: "150000 !important" }}
        open={isPending || isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
        width="80%"
      />
    </Dialog>
  );
}
