import {
  Backdrop,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { sort } from "fast-sort";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import ky from "ky";
import { useNavigate } from "react-router-dom";

import StandardAlert from "../alerts/StandardAlert";
import {
  enableSwipe,
  getItemFromStorage,
  notifyViaSlackOnFailure,
} from "../../utils/utils";

function formRegistrationBody(
  scout,
  selectedBadges,
  finalPrice,
  data,
  addCodes,
  food,
) {
  const sortedClasses = sort(selectedBadges).asc("startingSession");
  let classes = Array(4).fill("");
  sortedClasses.forEach((cls) => {
    classes[cls.startingSession - 1] = cls.meritBadgeName;
    classes[cls.endingSession - 1] = cls.meritBadgeName;
    const diff = cls.endingSession - cls.startingSession;
    if (diff > 0) {
      for (let i = diff; i > 0; i--) {
        classes[cls.endingSession - i] = cls.meritBadgeName;
      }
    }
  });

  return {
    participantId: scout.participantId,
    paymentMethod: "PayPal",
    paymentAmount: finalPrice.toFixed(2).toString(),
    session1BadgeName: classes[0],
    courseId1: classes[0]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[0]).courseId
      : null,
    session2BadgeName: classes[1],
    courseId2: classes[1]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[1]).courseId
      : null,
    session3BadgeName: classes[2],
    courseId3: classes[2]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[2]).courseId
      : null,
    session4BadgeName: classes[3],
    courseId4: classes[3]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[3]).courseId
      : null,
    orderId: data.orderID,
    addCodes: addCodes.map(Number),
    food,
  };
}

function PayPal(props) {
  const {
    finalPrice,
    setIsLoading,
    scout,
    selectedBadges,
    setAlertLevel,
    setAlertMessage,
    setAlertIsOpen,
    datastore,
    addCodes,
    food,
  } = props;
  const navigate = useNavigate();

  // Function to handle successful payment
  const handlePaymentSuccess = () => {
    setIsLoading(false);
    enableSwipe();
    navigate("/scouts", { registration: "Success" });
  };

  // Function to handle errors
  const handleError = (error) => {
    notifyViaSlackOnFailure(
      scout.firstName,
      scout.lastName,
      scout.unitType,
      scout.unitNumber,
      "initially register",
      error,
    );
    setIsLoading(false);
    setAlertLevel("error");
    setAlertMessage(
      "Something went wrong. Please contact us and we will help you fix the issue. Do NOT try to pay again please!",
    );
    setAlertIsOpen(true);
    console.error("Error:", error);
    // TODO: Undo DB changes on reg failure...
  };

  // Function to update participant registration with captureId
  const updateRegistrationCapture = (scout, captureId) => {
    return ky.patch(
      window._env_.REACT_APP_API_URL + "/edit-participant-registration-capture",
      {
        headers: {
          Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
        },
        json: {
          participantId: scout.participantId,
          configId: datastore.config.configId,
          captureId,
        },
        timeout: window._env_.REACT_APP_TIMEOUT,
      },
    );
  };

  return (
    <PayPalButtons
      forceReRender={[finalPrice]}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: finalPrice,
              },
            },
          ],
        });
      }}
      onApprove={(data, actions) => {
        setIsLoading(true);
        let registerData = formRegistrationBody(
          scout,
          selectedBadges,
          finalPrice,
          data,
          addCodes,
          food,
        );
        registerData.configId = datastore.config.configId;
        ky.post(window._env_.REACT_APP_API_URL + "/register-participant", {
          json: registerData,
          timeout: window._env_.REACT_APP_TIMEOUT,
          headers: {
            Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
          },
        })
          .then((response) => {
            // NOTE: Uncomment the line below to prevent payments
            // throw new Error("Developer threw error to stop payment");
            if (response.ok) {
              return actions.order.capture();
            } else {
              throw new Error("API call failed");
            }
          })
          .then((captureResponse) => {
            const captureId =
              captureResponse.purchase_units[0].payments.captures[0].id;
            return updateRegistrationCapture(scout, captureId);
          })
          .then(() => handlePaymentSuccess())
          .catch((error) => handleError(error));
      }}
    />
  );
}

export default function PaymentDialog(props) {
  const { datastore, addCodes } = props;
  const navigate = useNavigate();
  const [{ isPending }] = usePayPalScriptReducer();
  const { isOpen, setIsOpen, selectedBadges, price, scout } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [finalPrice, setFinalPrice] = useState(price);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        if (isChecked) {
          setFinalPrice(finalPrice - 2);
        }
        setIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>{scout.firstName}'s Registration</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography variant="body1">
          Please review the courses below you have selected for{" "}
          {scout.firstName}.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          <strong>Course Selections:</strong>
        </Typography>
        <Stack spacing={1}>
          {sort(selectedBadges)
            .asc("startingSession")
            .map((cls) => (
              <div key={cls.meritBadgeName}>
                <Chip
                  label={
                    cls.dur > 1
                      ? "Sessions " +
                        cls.startingSession +
                        " to " +
                        cls.endingSession +
                        ": " +
                        cls.meritBadgeName
                      : "Session " +
                        cls.startingSession +
                        ": " +
                        cls.meritBadgeName
                  }
                />
              </div>
            ))}
        </Stack>
        {props.food && (
          <>
            <Typography variant="h6" mt={1}>
              <strong>Food Selection:</strong>
            </Typography>
            <Typography variant="body1" mb={-2.5}>
              {props.food}
            </Typography>
          </>
        )}
      </DialogContent>
      {price > 0.0 ? (
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                value={isChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsChecked(true);
                    setFinalPrice(finalPrice + 2);
                  } else {
                    setIsChecked(false);
                    setFinalPrice(finalPrice - 2);
                  }
                }}
              />
            }
            label="Add $2 to cover PayPal fees?"
          />
          <Typography variant="h6">
            <strong>Total:</strong> ${finalPrice}.00
          </Typography>
        </DialogContent>
      ) : null}
      <DialogContent>
        {price === 0.0 ? (
          <>
            <Button
              variant="contained"
              onClick={() => {
                setIsLoading(true);
                let registerData = formRegistrationBody(
                  scout,
                  selectedBadges,
                  finalPrice,
                  { orderID: "pro bono registration" },
                  addCodes,
                  props.food,
                );
                registerData.configId = datastore.config.configId;
                registerData.captureId = "pro bono registration";
                ky.post(
                  window._env_.REACT_APP_API_URL + "/register-participant",
                  {
                    json: registerData,
                    timeout: window._env_.REACT_APP_TIMEOUT,
                    headers: {
                      Authorization:
                        "Bearer " + getItemFromStorage("mb_jwt_token"),
                    },
                  },
                )
                  .then((response) => {
                    setIsLoading(false);
                    if (response.ok) {
                      console.log("Registration successful!");
                      enableSwipe();
                      navigate("/scouts", { registration: "Success" });
                    } else {
                      throw new Error("API call failed");
                    }
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setAlertLevel("error");
                    setAlertMessage(
                      "Unable to complete payment. Please try again!",
                    );
                    setAlertIsOpen(true);
                    console.error("Error:", error);
                  });
              }}
            >
              Register w/out Paying
            </Button>
          </>
        ) : (
          <PayPal
            finalPrice={finalPrice}
            scout={scout}
            setIsLoading={setIsLoading}
            selectedBadges={selectedBadges}
            setAlertLevel={setAlertLevel}
            setAlertMessage={setAlertMessage}
            setAlertIsOpen={setAlertIsOpen}
            datastore={datastore}
            addCodes={addCodes}
            food={props.food}
          />
        )}
      </DialogContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: "150000 !important" }}
        open={isPending || isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
        width="80%"
      />
    </Dialog>
  );
}
