import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";

import EmailAccordion from "../accordians/EmailAccordion";
import KeyInfoAccordion from "../accordians/KeyInfoAccordion";
import ColorPaletteAccordion from "../accordians/ColorPaletteAccordion";
import DiscountsAccordion from "../accordians/DiscountsAccordion";
import FAQsAccordion from "../accordians/FAQsAccordion";
import AddressAccordion from "../accordians/AddressAccordion";
import SessionTimesAccordion from "../accordians/SessionTimesAccordion";
import EventDetailsAccordion from "../accordians/EventDetailsAccordion";
import DeveloperToolsAccordion from "../accordians/DeveloperToolsAccordion";
import ReleaseAccordion from "../accordians/ReleaseAccordion";
import { enableSwipe, getItemFromStorage } from "../../utils/utils";
import FilesAccordion from "../accordians/FilesAccordion";
import FoodAccordion from "../accordians/FoodAccordion";

function isProduction() {
  const host = window.location.host;
  const isLocalhost = host.includes("localhost");
  const isIpAddress = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d+)?$/.test(host);
  return !isLocalhost && !isIpAddress;
}

const EditConfigDialog = (props) => {
  const {
    open,
    setOpen,
    isLoading,
    setIsLoading,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
  } = props;
  const [config, setConfig] = useState({});
  const [validationError, setValidationError] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    const fetchConfig = () => {
      setIsLoading(true);
      ky.post(window._env_.REACT_APP_API_URL + "/get/full/config", {
        body: isProduction()
          ? window.location.host
          : window._env_.REACT_APP_HOST_OVERRIDE,
        headers: {
          "Content-Type": "text/plain", // Set the appropriate content type
          Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
        },
        timeout: window._env_.REACT_APP_TIMEOUT,
      })
        .json()
        .then((response) => {
          setConfig(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setOuterAlertLevel("error");
          setOuterAlertMessage("Error fetching config");
          setOuterAlertIsOpen(true);
          setOpen(false);
        });
    };
    fetchConfig();
  }, [triggerRefresh]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    enableSwipe();
    setOpen(false);
  };
  const handleValidationError = (error) => {
    setValidationError(error);
  };
  const handleSubmit = () => {
    if (!validationError) {
      setIsLoading(true);
      ky.post(window._env_.REACT_APP_API_URL + "/set/config", {
        json: config,
        timeout: window._env_.REACT_APP_TIMEOUT,
        headers: {
          Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
        },
      })
        .json()
        .then(() => {
          setIsLoading(false);
          setOuterAlertLevel("success");
          setOuterAlertMessage("Config saved successfully");
          setOuterAlertIsOpen(true);
          setOpen(false);
          enableSwipe();
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setOuterAlertLevel("error");
          setOuterAlertMessage("Error saving config");
          setOuterAlertIsOpen(true);
        });
    }
  };
  if (isLoading) {
    return null;
  } else if (isLoading === false && config.hasOwnProperty("configId")) {
    return (
      <div>
        <Button variant="outlined" onClick={handleClickOpen}>
          Edit Config
        </Button>
        <Dialog
          open={open}
          onClose={() => {
            handleClose();
          }}
        >
          <DialogTitle>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
                <strong>Website Configuration Editor</strong>
              </Typography>
              <Tooltip title="Close" arrow>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <strong>Config ID:</strong> {config.configId}
            </Typography>
            <Typography variant="body1" pb={2}>
              <strong>Event Domain:</strong> {config.eventDomain}
            </Typography>
            <EventDetailsAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
            />
            <FilesAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
              setTriggerConfigRefresh={setTriggerRefresh}
              triggerConfigRefresh={triggerRefresh}
              setIsLoading={setIsLoading}
              setOuterAlertLevel={setOuterAlertLevel}
              setOuterAlertMessage={setOuterAlertMessage}
              setOuterAlertIsOpen={setOuterAlertIsOpen}
            />
            <SessionTimesAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
            />
            <AddressAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
            />
            <FoodAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
            />
            <DiscountsAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
            />
            <ColorPaletteAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
            />
            <KeyInfoAccordion config={config} setConfig={setConfig} />
            <FAQsAccordion config={config} setConfig={setConfig} />
            <EmailAccordion config={config} setConfig={setConfig} />
            <ReleaseAccordion
              config={config}
              setConfig={setConfig}
              onValidationError={handleValidationError}
            />
            <DeveloperToolsAccordion config={config} setConfig={setConfig} />
          </DialogContent>
          <DialogContent>
            {validationError && (
              <Typography color="error" align="center">
                Please fix all errors before submitting!
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={validationError}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

export default EditConfigDialog;
