import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Link,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Divider,
  Checkbox,
} from "@mui/material";
import { utils, writeFileXLSX } from "xlsx";
import ky from "ky";

import {
  formatDate,
  getItemFromStorage,
  isAdmin,
  isOwner,
} from "../../utils/utils";
import NetIncomeChart from "../charts/NetIncomeChart";
import theme from "../../themes/theme";

const ParticipantTable = (props) => {
  const { datastore } = props;
  const [participantJson, setParticipantJson] = React.useState(null);
  const [filterName, setFilterName] = React.useState("");

  const START_DATE = formatDate(datastore.config.regOpenDate);
  const END_DATE = formatDate(datastore.config.eventDate);

  const columns = [
    {
      name: "participantId",
      label: "Participant ID",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "userId",
      label: "Parent ID",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "birthdate",
      label: "Birthdate",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "unitType",
      label: "Unit Type",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "unitNumber",
      label: "Unit #",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "participantEmailAddress",
      label: "Participant Email",
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <Link href={"mailto:" + value}>Email</Link>;
          } else {
            return "None";
          }
        },
      },
    },
    {
      name: "parentEmailAddress",
      label: "Parent Email",
      options: {
        display: true,
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (value) {
            return <Link href={"mailto:" + value}>Email</Link>;
          } else {
            return "None";
          }
        },
      },
    },
    {
      name: "paymentMethod",
      label: "Payment Method",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "paymentAmount",
      label: "Gross Payment",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "payPalFee",
      label: "Paypal Fee",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "netPaymentAmount",
      label: "Net Payment",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "initialRegistrationTime",
      label: "Registration Date",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          if (value) {
            return new Date(value).toLocaleString("en-US", {
              timeZone: "America/Los_Angeles",
            });
          } else {
            return "Not yet registered";
          }
        },
      },
    },
    {
      name: "session1BadgeName",
      label: "Session 1",
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (v) => "Session 1: " + v,
        },
        customBodyRender: (value, tableMeta) => {
          if (!value) return value;
          return `${value} (#${tableMeta.rowData[columns.findIndex((col) => col.name === "courseId1")]})`;
        },
        filterOptions: {
          logic: (location, filters) => {
            if (filters.length) {
              return !filters.includes(location);
            }
            return false;
          },
        },
      },
    },
    {
      name: "courseId1",
      label: "Session 1 Course ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "session2BadgeName",
      label: "Session 2",
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (v) => "Session 2: " + v,
        },
        customBodyRender: (value, tableMeta) => {
          if (!value) return value;
          return `${value} (#${tableMeta.rowData[columns.findIndex((col) => col.name === "courseId2")]})`;
        },
        filterOptions: {
          logic: (location, filters) => {
            if (filters.length) {
              return !filters.includes(location);
            }
            return false;
          },
        },
      },
    },
    {
      name: "courseId2",
      label: "Session 2 Course ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "session3BadgeName",
      label: "Session 3",
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (v) => "Session 3: " + v,
        },
        customBodyRender: (value, tableMeta) => {
          if (!value) return value;
          return `${value} (#${tableMeta.rowData[columns.findIndex((col) => col.name === "courseId3")]})`;
        },
        filterOptions: {
          logic: (location, filters) => {
            if (filters.length) {
              return !filters.includes(location);
            }
            return false;
          },
        },
      },
    },
    {
      name: "courseId3",
      label: "Session 3 Course ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "session4BadgeName",
      label: "Session 4",
      options: {
        filter: true,
        sort: true,
        customFilterListOptions: {
          render: (v) => "Session 4: " + v,
        },
        customBodyRender: (value, tableMeta) => {
          if (!value) return value;
          return `${value} (#${tableMeta.rowData[columns.findIndex((col) => col.name === "courseId4")]})`;
        },
        filterOptions: {
          logic: (location, filters) => {
            if (filters.length) {
              return !filters.includes(location);
            }
            return false;
          },
        },
      },
    },
    {
      name: "courseId4",
      label: "Session 4 Course ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "food",
      label: "Food",
    },
    {
      name: "orderId",
      label: "PayPal Order ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "captureId",
      label: "PayPal Capture ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "paidStatus",
      label: "Paid Status",
      options: {
        filter: true,
        filterType: "dropdown",
        filterOptions: {
          names: ["Paid", "Unpaid"],
          logic: (value, filters) => {
            if (filters.includes("Paid")) {
              return value !== "Paid";
            }
            if (filters.includes("Unpaid")) {
              return value !== "Unpaid";
            }
            return false;
          },
        },
        customBodyRender: (value) => (value === "Paid" ? "Paid" : "Unpaid"),
      },
    },
    {
      name: "isVoided",
      label: "Is Voided",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          if (value) {
            return "Yes";
          } else {
            return "No";
          }
        },
      },
    },
    {
      name: "isRefunded",
      label: "Is Refunded",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          if (value) {
            return "Yes";
          } else {
            return "No";
          }
        },
      },
    },
    {
      name: "isHere",
      label: "Attendance",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: () => {
          return <Checkbox />;
        },
      },
    },
  ];

  const paidStatusColumnIndex = columns.findIndex(
    (column) => column.name === "paidStatus",
  );

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    rowsPerPageOptions: [10, 15, 20, 25, 50, 100],
    rowsPerPage: 25,
    download: true,
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // Reshape data
      let formattedData = [];
      const keys = columns.map((c) => c.label);
      data.forEach((entry) => {
        const obj = {};
        keys.forEach((element, index) => {
          obj[element] = entry.data[index];
        });
        formattedData.push(obj);
      });
      // Convert to JSON to XLSX object
      const ws = utils.json_to_sheet(formattedData);
      // Determine widths for each column
      const fitToColumn = (data) => {
        const columnWidths = [];
        for (const property in data[0]) {
          columnWidths.push({
            wch: Math.max(
              property ? property.toString().length : 0,
              ...data.map((obj) =>
                obj[property] ? obj[property].toString().length : 0,
              ),
            ),
          });
        }
        return columnWidths;
      };
      // Set column widths
      ws["!cols"] = fitToColumn(formattedData);
      // Convert worksheet into workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      // Save workbook to file
      writeFileXLSX(
        wb,
        filterName === ""
          ? datastore.config.eventName + " Participant List.xlsx"
          : filterName + " Merit Badge.xlsx",
        { cellStyles: true },
      );
      // Return false to cancel original CSV download
      return false;
    },
    print: true,
    viewColumns: true,
    responsive: "vertical",
    sortThirdClickReset: true,
    onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
      if (changedColumn && changedColumn.endsWith("BadgeName")) {
        if (filterList[changedColumnIndex][0]) {
          setFilterName(filterList[changedColumnIndex][0]);
        } else {
          setFilterName("");
        }
      }
    },
    setRowProps: (row) => {
      return {
        style: {
          backgroundColor:
            row[paidStatusColumnIndex] === "Unpaid"
              ? theme.palette.error.light
              : "inherit",
        },
      };
    },
  };

  useEffect(() => {
    const url =
      window._env_.REACT_APP_API_URL +
      "/participants/" +
      datastore.config.configId;

    const fetchData = async () => {
      try {
        const json = await ky
          .get(url, {
            headers: {
              Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
            },
          })
          .json();
        const data = json.map((registrant) => ({
          ...registrant,
          paidStatus:
            !registrant.captureId && !registrant.isVoided ? "Unpaid" : "Paid",
        }));
        setParticipantJson(data);
      } catch (error) {
        console.error("Error fetching participants", error);
      }
    };

    fetchData();
  }, []);

  const frequencies = (array) =>
    array.reduce(
      (acc, item) =>
        array.filter((v) => v === acc).length >=
        array.filter((v) => v === item).length
          ? acc
          : item,
      null,
    );

  const dayWithMostRegistrants = (json) => {
    return frequencies(
      json.map((e) => new Date(e.initialRegistrationTime).setHours(0, 0, 0, 0)),
    );
  };

  const mostRegInDay = (json) => {
    return json
      .map((e) => new Date(e.initialRegistrationTime).setHours(0, 0, 0, 0))
      .filter((e) => e === dayWithMostRegistrants(json)).length;
  };

  const regOpenFor = () => {
    const startingDate = new Date(START_DATE);
    const endingDate = new Date(END_DATE);
    const todayDate = new Date();
    if (todayDate > endingDate) {
      const differenceInTime = endingDate.getTime() - startingDate.getTime();
      return Math.round(differenceInTime / (1000 * 3600 * 24));
    } else {
      const differenceInTime = todayDate.getTime() - startingDate.getTime();
      return Math.round(differenceInTime / (1000 * 3600 * 24));
    }
  };

  const daysRemaining = () => {
    const endingDate = new Date(END_DATE);
    const todayDate = new Date();
    if (todayDate > endingDate) {
      return 0;
    } else {
      const differenceInTime = endingDate.getTime() - todayDate.getTime();
      return Math.round(differenceInTime / (1000 * 3600 * 24));
    }
  };

  const avgRegInDay = (json) => {
    const start = new Date(START_DATE);
    const end = new Date(END_DATE);
    const now = new Date();

    // Ensure the end date is not after the current date
    const actualEndDate = now < end ? now : end;
    const totalDays = Math.ceil(
      (actualEndDate - start) / (1000 * 60 * 60 * 24),
    );
    if (totalDays > 0) {
      const dateCounts = new Array(totalDays).fill(0);

      // Count the number of registrations per day
      json.forEach((registration) => {
        const registrationDate = new Date(registration.initialRegistrationTime);
        if (registrationDate >= start && registrationDate <= actualEndDate) {
          const dayIndex = Math.floor(
            (registrationDate - start) / (1000 * 60 * 60 * 24),
          );
          dateCounts[dayIndex]++;
        }
      });

      // Calculate the average registrations per day
      const totalRegistrations = dateCounts.reduce(
        (total, count) => total + count,
        0,
      );
      const averageRegistrationsPerDay = totalRegistrations / totalDays;

      return Math.round(averageRegistrationsPerDay);
    } else {
      return 0;
    }
  };

  const grossIncome =
    participantJson && participantJson.length > 0
      ? participantJson
          .reduce((acc, obj) => acc + parseFloat(obj.paymentAmount || 0), 0)
          .toFixed(2)
      : 0.0;

  const payPalFees =
    participantJson && participantJson.length > 0
      ? parseFloat(
          participantJson
            .reduce((acc, obj) => acc + parseFloat(obj.payPalFee || 0), 0)
            .toFixed(2),
        )
      : 0.0;

  const netIncome =
    participantJson && participantJson.length > 0
      ? participantJson
          .reduce((acc, obj) => acc + parseFloat(obj.netPaymentAmount || 0), 0)
          .toFixed(2)
      : 0.0;

  const totalParticipants =
    participantJson && participantJson.length > 0 ? participantJson.length : 0;
  const registeredParticipants =
    participantJson && participantJson.length > 0
      ? participantJson.filter((p) => p.initialRegistrationTime).length
      : 0;
  const uniqueUnits =
    participantJson && participantJson.length > 0
      ? new Set(participantJson.map((p) => p.unitNumber)).size
      : 0;
  const registeredToday =
    participantJson && participantJson.length > 0
      ? participantJson.filter(
          (p) =>
            new Date(p.initialRegistrationTime).setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0),
        ).length
      : 0;

  const stats = participantJson
    ? [
        {
          groupName: "Participants",
          stats: [
            {
              title: "Registered Scouts",
              value: registeredParticipants,
            },
            {
              title: "Total Participants",
              value: totalParticipants,
            },
            {
              title: "Unique Units",
              value: uniqueUnits,
            },
            {
              title: "Registered Today",
              value: registeredToday,
            },
          ],
        },
        {
          groupName: "Timeline",
          stats: [
            {
              title: "Days Remaining",
              value: daysRemaining(),
            },
            {
              title: "Days Open",
              value: regOpenFor(),
            },
            {
              title: "Best Day",
              value:
                dayWithMostRegistrants(participantJson) !== -57600000
                  ? new Date(
                      dayWithMostRegistrants(participantJson),
                    ).toLocaleString("en-US", {
                      timeZone: "America/Los_Angeles",
                      year: "numeric",
                      month: "2-digit",
                      day: "numeric",
                    })
                  : "N/A",
            },
            {
              title: "Avg Daily Registers",
              value: avgRegInDay(participantJson),
            },
          ],
        },
      ]
    : [];

  // Conditionally add the Income group if the user is an owner or admin
  if (participantJson && (isOwner() || isAdmin())) {
    stats.push({
      groupName: "Income",
      stats: [
        {
          title: "Net Income",
          value: `$${netIncome}`,
        },
        {
          title: "Gross Income",
          value: `$${grossIncome}`,
        },
        {
          title: "PayPal Fees",
          value: `$${payPalFees}`,
        },
        {
          title: "Most Registers in a Day",
          value: mostRegInDay(participantJson),
        },
      ],
    });
  }
  return (
    <div>
      {/* participantJson?.status !== 404 catches case that there are no participants registered yet... */}
      {participantJson && participantJson?.status !== 404 ? (
        <>
          {isOwner() || isAdmin() ? (
            <NetIncomeChart data={participantJson} />
          ) : null}
          <Paper sx={{ marginBottom: 2, padding: 2 }}>
            <Typography variant="h5">
              <strong>Statistics</strong>
            </Typography>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <Grid container spacing={4}>
              {stats.map((group, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={isOwner() || isAdmin() ? 4 : 6}
                >
                  <Typography variant="h6" component="div" gutterBottom>
                    <strong>{group.groupName}</strong>
                  </Typography>
                  <Grid container spacing={2}>
                    {group.stats.map((stat, statIndex) => (
                      <Grid key={statIndex} item xs={12}>
                        <Card
                          elevation={statIndex === 0 ? 5 : 1}
                          sx={{
                            backgroundColor:
                              statIndex === 0
                                ? "primary.main"
                                : "background.paper",
                            color:
                              statIndex === 0
                                ? "primary.contrastText"
                                : "text.primary",
                          }}
                        >
                          <CardContent>
                            <Typography
                              variant="body1"
                              component="div"
                              gutterBottom
                            >
                              <strong>{stat.title}:</strong>
                            </Typography>
                            <Typography
                              variant="body2"
                              color={
                                statIndex === 0 ? "inherit" : "text.secondary"
                              }
                            >
                              {stat.value}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
          <MUIDataTable
            data={participantJson}
            columns={columns}
            options={options}
            style={{ boxShadow: "none !important" }}
          />
        </>
      ) : null}
    </div>
  );
};

export default ParticipantTable;
