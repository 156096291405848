import React, { useState, useRef } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/cjs/Page/TextLayer.css";
import ky from "ky";

import StandardAlert from "../alerts/StandardAlert";
import { getItemFromStorage } from "../../utils/utils";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const FilesAccordion = ({
  config,
  setTriggerConfigRefresh,
  triggerConfigRefresh,
  setIsLoading,
  setOuterAlertLevel,
  setOuterAlertMessage,
  setOuterAlertIsOpen,
}) => {
  const pdfFileInputRef = useRef(null);
  const imgFileInputRef = useRef(null);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertLevel, setAlertLevel] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const handlePdfButtonClick = () => {
    if (pdfFileInputRef.current) {
      pdfFileInputRef.current.click();
    }
  };

  const handleImgButtonClick = () => {
    if (imgFileInputRef.current) {
      imgFileInputRef.current.click();
    }
  };

  const handlePdfFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile || !selectedFile.name.endsWith(".pdf")) {
      setAlertLevel("error");
      setAlertMessage("Please select a valid PDF file");
      setAlertIsOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setIsLoading(true);
      const response = await ky.post(
        `${window._env_.REACT_APP_API_URL}/uploadFile/${config.configId}`,
        {
          body: formData,
          timeout: false,
          headers: {
            Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
          },
        },
      );
      if (response.status === 200) {
        setTriggerConfigRefresh(!triggerConfigRefresh);
        setIsLoading(false);
        setOuterAlertLevel("success");
        setOuterAlertMessage("File uploaded successfully");
        setOuterAlertIsOpen(true);
      } else {
        setIsLoading(false);
        setAlertLevel("error");
        setAlertMessage("Upload failed");
        setAlertIsOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading file:", error);
      setAlertLevel("error");
      setAlertMessage("An error occurred during upload");
      setAlertIsOpen(true);
    }
  };

  const handleImgFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (
      !selectedFile ||
      !selectedFile.name.match(/\.(pdf|jpg|jpeg|gif|svg)$/i)
    ) {
      setAlertLevel("error");
      setAlertMessage(
        "Please select a valid image file format. (PDF, JPG, JPEG, GIF, SVG)",
      );
      setAlertIsOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setIsLoading(true);
      const response = await ky.post(
        `${window._env_.REACT_APP_API_URL}/uploadFile/${config.configId}`,
        {
          body: formData,
          timeout: false,
          headers: {
            Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
          },
        },
      );
      if (response.status === 200) {
        setTriggerConfigRefresh(!triggerConfigRefresh);
        setIsLoading(false);
        setOuterAlertLevel("success");
        setOuterAlertMessage("File uploaded successfully");
        setOuterAlertIsOpen(true);
      } else {
        setIsLoading(false);
        setAlertLevel("error");
        setAlertMessage("Upload failed");
        setAlertIsOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading file:", error);
      setAlertLevel("error");
      setAlertMessage("An error occurred during upload");
      setAlertIsOpen(true);
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Event Files</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" pb={3}>
            This section is used to upload a logos and flyer. This is optional
            and if left blank, the Scouts BSA logo will be used.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack alignItems="center">
                <Typography variant="body1" pb={3}>
                  <strong>Current Logo</strong>
                </Typography>
                <img
                  alt="logo"
                  width={100}
                  src={
                    config.logoFileId
                      ? window._env_.REACT_APP_API_URL +
                        "/getFile/" +
                        config.configId +
                        "/" +
                        config.logoFileId
                      : "./bsa.svg"
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack alignItems="center">
                <Typography variant="body1" pb={3}>
                  <strong>Current Flyer</strong>
                </Typography>
                {config.flyerFileId !== null ? (
                  <Document
                    file={
                      window._env_.REACT_APP_API_URL +
                      "/getFile/" +
                      config.configId +
                      "/" +
                      config.flyerFileId
                    }
                  >
                    <Page
                      renderAnnotationLayer={false}
                      pageNumber={1}
                      width={100}
                    />
                  </Document>
                ) : (
                  <Typography variant="body1" pb={3}>
                    No flyer uploaded
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={6} align="center">
              <input
                type="file"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                style={{ display: "none" }}
                ref={imgFileInputRef}
                onChange={handleImgFileChange}
              />
              <Button
                variant="contained"
                size="small"
                onClick={handleImgButtonClick}
              >
                Upload New Logo
              </Button>
            </Grid>
            <Grid item xs={6} align="center" pb={1}>
              <input
                type="file"
                accept=".pdf"
                style={{ display: "none" }}
                ref={pdfFileInputRef}
                onChange={handlePdfFileChange}
              />
              <Button
                variant="contained"
                size="small"
                onClick={handlePdfButtonClick}
              >
                Upload New Flyer
              </Button>
            </Grid>
          </Grid>
          <Typography variant="caption">
            *Logos must be <strong>.svg, .png, .jpg, .jpeg,</strong> or{" "}
            <strong>.gif</strong> and flyers must be <strong>.pdf.</strong>
          </Typography>
        </Box>
      </AccordionDetails>
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
      />
    </Accordion>
  );
};

export default FilesAccordion;
