import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { sort } from "fast-sort";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import { useNavigate } from "react-router-dom";

import StandardAlert from "../alerts/StandardAlert";
import {
  enableSwipe,
  getItemFromStorage,
  notifyViaSlackOnFailure,
} from "../../utils/utils";

function formRegistrationBody(scout, selectedBadges, addCodes, food) {
  const sortedClasses = sort(selectedBadges).asc("startingSession");
  let classes = Array(4).fill("");
  sortedClasses.forEach((cls) => {
    classes[cls.startingSession - 1] = cls.meritBadgeName;
    classes[cls.endingSession - 1] = cls.meritBadgeName;
    const diff = cls.endingSession - cls.startingSession;
    if (diff > 0) {
      for (let i = diff; i > 0; i--) {
        classes[cls.endingSession - i] = cls.meritBadgeName;
      }
    }
  });

  return {
    participantId: scout.participantId,
    session1BadgeName: classes[0],
    courseId1: classes[0]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[0]).courseId
      : null,
    session2BadgeName: classes[1],
    courseId2: classes[1]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[1]).courseId
      : null,
    session3BadgeName: classes[2],
    courseId3: classes[2]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[2]).courseId
      : null,
    session4BadgeName: classes[3],
    courseId4: classes[3]
      ? sortedClasses.find((el) => el.meritBadgeName === classes[3]).courseId
      : null,
    addCodes: addCodes.map(Number),
    food,
  };
}

export default function EditConfirmationDialog(props) {
  const { datastore, addCodes, food } = props;
  const navigate = useNavigate();
  const { isOpen, setIsOpen, selectedBadges, scout } = props;
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>{scout.firstName}'s Updated Registration</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography variant="body1">
          Please review the updated courses below you have selected for{" "}
          {scout.firstName}.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">
          <strong>Course Selections:</strong>
        </Typography>
        <Stack spacing={1}>
          {sort(selectedBadges)
            .asc("startingSession")
            .map((cls) => (
              <div key={cls.meritBadgeName}>
                <Chip
                  label={
                    cls.dur > 1
                      ? "Sessions " +
                        cls.startingSession +
                        " to " +
                        cls.endingSession +
                        ": " +
                        cls.meritBadgeName
                      : "Session " +
                        cls.startingSession +
                        ": " +
                        cls.meritBadgeName
                  }
                />
              </div>
            ))}
        </Stack>
        {props.food && (
          <>
            <Typography variant="h6" mt={1}>
              <strong>Food Selection:</strong>
            </Typography>
            <Typography variant="body1" mb={-2.5}>
              {props.food}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogContent>
        <Typography variant="bod1">
          Use the the button below to apply the above changes to your scout's
          registration
        </Typography>
      </DialogContent>
      <DialogContent>
        <Button
          variant="contained"
          onClick={() => {
            setIsLoading(true);
            let registerData = formRegistrationBody(
              scout,
              selectedBadges,
              addCodes,
              food,
            );
            registerData.configId = datastore.config.configId;
            ky.patch(window._env_.REACT_APP_API_URL + "/edit-participant", {
              json: registerData,
              timeout: window._env_.REACT_APP_TIMEOUT,
              headers: {
                Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
              },
            })
              .then((response) => {
                setIsLoading(false);
                if (response.ok) {
                  console.log("Edit Registration successful!");
                  enableSwipe();
                  navigate("/scouts", { state: { registration: "Success" } });
                } else {
                  throw new Error("API call failed");
                }
              })
              .catch((error) => {
                notifyViaSlackOnFailure(
                  scout.firstName,
                  scout.lastName,
                  scout.unitType,
                  scout.unitNumber,
                  "update registration",
                  error,
                );
                setIsLoading(false);
                setAlertLevel("error");
                setAlertMessage(
                  "Unable to edit registration. Please refresh the page try again!",
                );
                setAlertIsOpen(true);
                console.error("Error:", error);
              });
          }}
        >
          Confirm Changes
        </Button>
      </DialogContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: "150000 !important" }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
        width="80%"
      />
    </Dialog>
  );
}
