import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ky from "ky";
import * as React from "react";
import { useEffect, useState } from "react";

import StandardAlert from "../alerts/StandardAlert";
import { enableSwipe, getItemFromStorage } from "../../utils/utils";

export default function RemoveClassDialog(props) {
  const {
    removeCourseIsOpen,
    setRemoveCourseIsOpen,
    setIsLoading,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    datastore,
  } = props;

  const [allBadges, setAllBadges] = useState([]);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertLevel, setAlertLevel] = useState("error");
  const [selectedBadge, setSelectedBadge] = useState({ courseId: "Select" });

  useEffect(() => {
    const fetchBadges = () => {
      return ky
        .get(
          window._env_.REACT_APP_API_URL +
            "/courses/" +
            datastore.config.configId,
          {
            timeout: window._env_.REACT_APP_TIMEOUT,
          },
        )
        .json();
    };
    setIsLoading(true);
    fetchBadges()
      .then((data) => {
        const filteredArray = data.map((subArray) =>
          subArray.filter((obj) => obj.spotsRemaining === obj.maxCapacity),
        );
        setAllBadges(filteredArray.flat());
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setOuterAlertLevel("error");
        setOuterAlertMessage("Error: Could not fetch classes.");
        setOuterAlertIsOpen(true);
      });
  }, []);

  const handleBadgeSelect = (badge) => {
    // console.log(badge);
    if (badge === "Select") {
      setSelectedBadge({ courseId: "Select" });
    } else if (badge === null) {
      // Case where the user deletes the text, do nothing
    } else {
      setSelectedBadge(badge);
    }
  };

  return (
    <Dialog
      open={removeCourseIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setRemoveCourseIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Remove a Class</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setRemoveCourseIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Select the class you want to remove.
        </Typography>
        <Typography variant="body1">
          Keep in mind you can only remove classes that do NOT have any
          registered scouts! This is why you won't see any courses that have
          scouts registered in the drop down below.
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Autocomplete
            options={[{ courseId: "Select" }, ...allBadges]}
            getOptionLabel={(option) => {
              if (option.courseId === "Select") return option.courseId;
              else {
                return `${option.meritBadgeName} - ID: ${option.courseId} - Sess: ${option.startingSession}`;
              }
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(option, value);
              return option.courseId === value.courseId;
            }}
            value={selectedBadge}
            onChange={(event, newValue) => {
              handleBadgeSelect(newValue ? newValue : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Class"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogContent>
        <Button
          variant="contained"
          onClick={() => {
            setIsLoading(true);
            const body = {
              courseId: selectedBadge.courseId,
              configId: datastore.config.configId,
            };
            ky.delete(window._env_.REACT_APP_API_URL + "/deletecourse", {
              json: body,
              timeout: window._env_.REACT_APP_TIMEOUT,
              headers: {
                Authorization: "Bearer " + getItemFromStorage("mb_jwt_token"),
              },
            })
              .then(() => {
                setIsLoading(false);
                setOuterAlertLevel("success");
                setOuterAlertMessage("Course removed successfully!");
                setOuterAlertIsOpen(true);
                setRemoveCourseIsOpen(false);
                enableSwipe();
              })
              .catch((err) => {
                setIsLoading(false);
                setAlertLevel("error");
                setAlertMessage(
                  "Error removing course! Please ensure no one is registered in this course.",
                );
                setAlertIsOpen(true);
                console.log(err);
              });
            console.log(selectedBadge);
          }}
        >
          Remove Class
        </Button>
      </DialogContent>
      <StandardAlert
        alertIsOpen={alertIsOpen}
        setAlertIsOpen={setAlertIsOpen}
        alertLevel={alertLevel}
        alertMessage={alertMessage}
        width="80%"
      />
    </Dialog>
  );
}
