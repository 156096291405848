import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import DialogActions from "@mui/material/DialogActions";
import { useEffect, useState } from "react";
import ky from "ky";
import { useNavigate } from "react-router-dom";

import { enableSwipe, generateRandomSixDigitInt } from "../../utils/utils";

export default function VerifyEmailDialog(props) {
  const {
    isOpen,
    setIsOpen,
    datastore,
    formValues,
    setBackdropIsOpen,
    setAlertIsOpen,
    setAlertMessage,
    setAlertLevel,
  } = props;
  const navigate = useNavigate();
  const [randomCode, setRandomCode] = React.useState(0);
  const [typedCode, setTypedCode] = React.useState("");
  const [codeValidated, setCodeValidated] = React.useState(false);
  const [triggerCreateAccount, setTriggerCreateAccount] = useState(false);

  useEffect(() => {
    if (parseInt(typedCode) === parseInt(randomCode)) {
      setCodeValidated(true);
    } else {
      setCodeValidated(false);
    }
  }, [typedCode]);

  useEffect(() => {
    const rando = generateRandomSixDigitInt();
    setRandomCode(rando);
    const sendEmail = async () => {
      setBackdropIsOpen(true);
      await ky
        .post(window._env_.REACT_APP_API_URL + "/auth/send-email", {
          json: {
            recipientEmail: formValues.email,
            subject: "Merit Badge Event Verification Code",
            body: "Your account verification code is " + rando.toString() + ".",
            configId: datastore.config.configId,
          },
          timeout: window._env_.REACT_APP_TIMEOUT,
        })
        .json();
    };
    sendEmail()
      .then(() => {
        setBackdropIsOpen(false);
        setAlertMessage(
          "Email sent! Please check your inbox for a 6 digit code.",
        );
        setAlertLevel("success");
        setAlertIsOpen(true);
      })
      .catch(() => {
        setBackdropIsOpen(false);
        setAlertMessage("Error: Could not send email. Please verify email.");
        setAlertLevel("error");
        setAlertIsOpen(true);
      });
  }, []);

  useEffect(() => {
    if (triggerCreateAccount === true) {
      setBackdropIsOpen(true);
      ky.post(window._env_.REACT_APP_API_URL + "/auth/signup", {
        json: formValues,
        timeout: window._env_.REACT_APP_TIMEOUT,
      })
        .then(() => {
          enableSwipe();
          setBackdropIsOpen(false);
          setTriggerCreateAccount(false);
          navigate("/login", { state: { accountJustCreated: true } });
        })
        .catch(() => {
          setAlertLevel("error");
          setAlertMessage("Error: Could not create new account.");
          setBackdropIsOpen(false);
          setAlertIsOpen(true);
          setTriggerCreateAccount(false);
        });
    }
  }, [triggerCreateAccount]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
        enableSwipe();
        setIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Email Verification</strong>
          </Typography>
          <Tooltip
            title="Closing this window will void the last sent code!"
            arrow
          >
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent justifyContent="center">
        <Typography variant="body2">
          Please check your email for the six digit verification code. Then type
          it into the box below. Be sure to check your junk/spam folder.
        </Typography>
        <TextField
          sx={{ mt: 2 }}
          size="small"
          name="code"
          value={typedCode}
          variant="outlined"
          onChange={(e) => setTypedCode(e.target.value)}
          placeholder="Enter code here"
          helperText={
            typedCode.length === 6
              ? codeValidated
                ? "Valid Code!"
                : "The entered code is incorrect :("
              : "Check your email for a 6 digit code"
          }
          error={typedCode.length === 6 && !codeValidated}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!codeValidated}
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => {
            setTriggerCreateAccount(true);
          }}
        >
          Create Account
        </Button>
      </DialogActions>
    </Dialog>
  );
}
