import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import theme from "./themes/theme";
import RouterRoutes from "./routes/Route";
/* eslint-disable */
import TechnicalDifficulties from "./views/TechnicalDifficulties";
import Maintenance from "./views/Maintenance";
import ConfigDatastore from "./datastores/ConfigDatastore";
import Loading from "./views/Loading";
import { useSwipeable } from "react-swipeable";
import { isMobileDevice } from "./utils/utils";

const configDatastore = new ConfigDatastore();

const App = observer(() => {

  // Header State
  const [headerOpen, setHeaderOpen] = React.useState(false);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [footerHeight, setFooterHeight] = React.useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isMobileDevice() && sessionStorage.getItem("disableSwipe") === "false") {
        setHeaderOpen(false);
      }
    },
    onSwipedRight: () => {
      if (isMobileDevice() && sessionStorage.getItem("disableSwipe") === "false") {
        setHeaderOpen(true);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  React.useEffect(() => {
    configDatastore.fetchConfig();
  }, []);

  if (configDatastore.isLoading === false && configDatastore.isError === false) {
    if (configDatastore.config.isMaintenance === true && !window.location.href.includes("3000")) {
      return (
        <Maintenance />
      );
    } else {
      // Override theme values from config
      configDatastore?.config?.colorPalette?.light ? theme.palette.primary.light = configDatastore.config.colorPalette.light : null;
      configDatastore?.config?.colorPalette?.main ? theme.palette.primary.main = configDatastore.config.colorPalette.main : null;
      configDatastore?.config?.colorPalette?.dark ? theme.palette.primary.dark = configDatastore.config.colorPalette.dark : null;
      configDatastore?.config?.colorPalette?.contrastText ? theme.palette.primary.contrastText = configDatastore.config.colorPalette.contrastText : null;
      // Secondary palette is opposite of primary
      configDatastore?.config?.colorPalette?.dark ? theme.palette.secondary.light = configDatastore.config.colorPalette.dark : null;
      configDatastore?.config?.colorPalette?.main ? theme.palette.secondary.main = configDatastore.config.colorPalette.main : null;
      configDatastore?.config?.colorPalette?.light ? theme.palette.secondary.dark = configDatastore.config.colorPalette.light : null;
      configDatastore?.config?.colorPalette?.contrastText ? theme.palette.secondary.contrastText = configDatastore.config.colorPalette.contrastText : null;
      return (
        <div {...handlers}>
          <Helmet>
            <title>{configDatastore.config.eventName}</title>
            <link rel="icon" href={
              configDatastore.config.logoFileId
                ? window._env_.REACT_APP_API_URL +
                "/getFile/" +
                configDatastore.config.configId +
                "/" +
                configDatastore.config.logoFileId
                : "./bsa.svg"
            } />
          </Helmet>
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <CssBaseline />
              <RouterRoutes datastore={configDatastore} headerOpen={headerOpen} setHeaderOpen={setHeaderOpen}
                            headerHeight={headerHeight} setHeaderHeight={setHeaderHeight} footerHeight={footerHeight}
                            setFooterHeight={setFooterHeight} />
            </React.Fragment>
          </ThemeProvider>
        </div>
      );
    }
  } else if (configDatastore.isLoading === true && configDatastore.isError === false) {
    return (
      <Loading />
    );
  } else if (configDatastore.isLoading === false && configDatastore.isError === true) {
    return (
      <TechnicalDifficulties />
    );
  }
});

export default App;
