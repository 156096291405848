import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { useState } from "react";
import ky from "ky";
import { parsePhoneNumber } from "awesome-phonenumber";

import StandardAlert from "../alerts/StandardAlert";
import { capitalize, enableSwipe, getItemFromStorage } from "../../utils/utils";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  unitType: "Select",
  unitNumber: "",
  gender: "Select",
  birthdate: "",
};
export default function AddScoutDialog(props) {
  const {
    addScoutIsOpen,
    setAddScoutIsOpen,
    setIsLoading,
    setTriggerRefresh,
    triggerRefresh,
    setOuterAlertLevel,
    setOuterAlertMessage,
    setOuterAlertIsOpen,
    datastore,
  } = props;

  const [formValues, setFormValues] = useState(defaultValues);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "firstName":
      case "lastName":
        setFormValues({
          ...formValues,
          [name]: capitalize(value),
        });
        break;

      case "phone":
        console.log(value);
        const pn = parsePhoneNumber(value, { regionCode: "US" });
        setPhoneError(!pn.valid); // Set phone error based on validity
        setFormValues({
          ...formValues,
          [name]: pn.valid ? pn.number.national : value,
        });
        break;

      case "email":
        const isValidEmail = validateEmail(value);
        setFormValues({
          ...formValues,
          [name]: value,
        });
        setEmailError(!isValidEmail); // Set email error based on validity
        break;

      default:
        setFormValues({
          ...formValues,
          [name]: value,
        });
        break;
    }
  };

  // Helper function for email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Dialog
      open={addScoutIsOpen}
      maxWidth="xs"
      onClose={() => {
        enableSwipe();
        setAddScoutIsOpen(false);
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" noWrap sx={{ flexGrow: 1 }}>
            <strong>Add a Scout</strong>
          </Typography>
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={() => {
                enableSwipe();
                setAddScoutIsOpen(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <strong>
            Please fill out the form below with your scout's information. The
            merit badge counselors will use the information below for
            attendance.{" "}
          </strong>
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="Scout's First Name"
            name="firstName"
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Scout's Last Name"
            name="lastName"
            onChange={handleInputChange}
          />
          <TextField
            select
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
            required
            value={formValues.gender}
            label="Scout's Gender"
            name="gender"
            onChange={handleInputChange}
          >
            <option value="Select" disabled={true}>
              Select
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </TextField>
          <TextField
            margin="normal"
            fullWidth
            id="birthdate-input"
            name="birthdate"
            label="Scout's Birthdate"
            type="date"
            value={formValues.birthdate}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true, required: true }}
          />

          <TextField
            select
            margin="normal"
            fullWidth
            SelectProps={{
              native: true,
            }}
            required
            value={formValues.unitType}
            label="Scout's Unit Type"
            name="unitType"
            onChange={handleInputChange}
          >
            <option value="Select" disabled={true}>
              Select
            </option>
            <option value="Troop">Troop</option>
            <option value="Crew">Crew</option>
            <option value="Ship">Ship</option>
          </TextField>
          <TextField
            required
            margin="normal"
            fullWidth
            id="unit-number-input"
            name="unitNumber"
            label="Scout's Unit Number"
            type="number"
            value={formValues.unitNumber}
            onChange={handleInputChange}
          />
          <Divider sx={{ paddingTop: 1 }} />
          <Typography variant="h6" pt={1}>
            <strong>Optional fields:</strong>
          </Typography>
          <Typography variant="body1">
            The following fields are available to optionally send your scout a
            copy of their registration.
          </Typography>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Scout's Email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Scout's Phone Number"
            helperText="10 digit phone number"
            name="phone"
            value={formValues.phone}
            onChange={handleInputChange}
          />

          <Button
            onClick={() => {
              if (
                formValues.firstName !== "" &&
                formValues.lastName !== "" &&
                formValues.birthdate !== "" &&
                formValues.unitNumber !== "" &&
                formValues.unitType !== "Select" &&
                formValues.gender !== "Select" &&
                phoneError !== true &&
                emailError !== true
              ) {
                setIsLoading(true);
                ky.post(window._env_.REACT_APP_API_URL + "/add-participant", {
                  json: {
                    userId: getItemFromStorage("mb_user_id"),
                    configId: datastore.config.configId,
                    ...formValues,
                  },
                  timeout: window._env_.REACT_APP_TIMEOUT,
                  headers: {
                    Authorization:
                      "Bearer " + getItemFromStorage("mb_jwt_token"),
                  },
                })
                  .json()
                  .then(() => {
                    setIsLoading(false);
                    setOuterAlertLevel("success");
                    setOuterAlertMessage(
                      "Scout Added! You may need to refresh to see the changes!",
                    );
                    setOuterAlertIsOpen(true);
                    setTriggerRefresh(!triggerRefresh);
                    setFormValues(defaultValues);
                    setAddScoutIsOpen(false);
                    enableSwipe();
                  })
                  .catch((e) => {
                    console.error(e);
                    setOuterAlertLevel("error");
                    setAlertMessage("Unable to add scout. Please try again!");
                    setAlertIsOpen(true);
                    setIsLoading(false);
                  });
              } else if (phoneError) {
                setOuterAlertLevel("error");
                setAlertMessage("Invalid Phone Number!");
                setAlertIsOpen(true);
              } else if (emailError) {
                setOuterAlertLevel("error");
                setAlertMessage("Invalid E-Mail Address!");
                setAlertIsOpen(true);
              } else {
                setOuterAlertLevel("error");
                setAlertMessage(
                  "Please make sure all required fields are populated!",
                );
                setAlertIsOpen(true);
              }
            }}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Scout
          </Button>
        </Box>
        <StandardAlert
          alertIsOpen={alertIsOpen}
          setAlertIsOpen={setAlertIsOpen}
          alertLevel="error"
          alertMessage={alertMessage}
          width="80%"
        />
      </DialogContent>
    </Dialog>
  );
}
