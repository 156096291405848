import { useMediaQuery } from "@mui/material";

const isMobileDevice = () => {
  const vendor = navigator.userAgent || navigator.vendor || window.opera;

  return !!(
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      vendor,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      vendor.substr(0, 4),
    )
  );
};

function isSmallScreen() {
  return window.innerWidth <= 600;
}

function isSmallOrMobileDevice() {
  return isSmallScreen() || isMobileDevice();
}

function getItemFromStorage(key) {
  // Check if username and session status are available and valid
  const sessionStorageJSON = localStorage.getItem(
    "mb_college_" + window.location.host,
  );
  if (sessionStorageJSON) {
    const sessionStorage = JSON.parse(sessionStorageJSON);
    if (sessionStorage[key]) {
      return sessionStorage[key];
    }
    return "Key not found";
  }
  return "Session storage not found";
}

function isLoggedIn() {
  const token = getItemFromStorage("mb_jwt_token");

  return !isTokenExpired(token);
}

function isTokenExpired(token) {
  if (!isValidJwt(token)) {
    console.log("JWT Token is not valid");
    return true;
  }

  // Decode the token payload
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join(""),
  );

  // Parse the JSON payload
  const payload = JSON.parse(jsonPayload);

  // Check if the token is expired
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  if (!payload.exp) {
    console.log("JWT Token does not have an expiration date");
    return false;
  }
  const isExp = payload.exp < currentTime;
  if (isExp) {
    localStorage.setItem(
      "mb_college_" + window.location.host,
      JSON.stringify({}),
    );
    // TODO: Add a snackbar alert here
    alert("Your session has expired. Please log in again.");
    console.log("JWT Token is expired");
    return true;
  } else {
    console.log("JWT Token is valid");
    return false;
  }
}

function isValidJwt(token) {
  if (!token) {
    return false; // No token found
  }

  const parts = token.split(".");

  // JWT should have exactly three parts: header, payload, and signature
  if (parts.length !== 3) {
    return false;
  }

  try {
    // Validate if the header and payload are Base64-encoded
    const base64UrlRegex = /^[A-Za-z0-9_-]+$/;

    if (!base64UrlRegex.test(parts[0]) || !base64UrlRegex.test(parts[1])) {
      return false;
    }

    // Decode the payload to ensure it can be parsed as JSON
    const payload = JSON.parse(
      atob(parts[1].replace(/-/g, "+").replace(/_/g, "/")),
    );

    // Optionally check if 'exp' exists in the payload to confirm it's a JWT
    return payload && typeof payload === "object" && "exp" in payload;
  } catch (error) {
    return false; // Invalid Base64 or JSON parsing error
  }
}

function isCounselor() {
  return getItemFromStorage("mb_privilege_level") === "counselor";
}

function isAdmin() {
  return getItemFromStorage("mb_privilege_level") === "admin";
}

function isOwner() {
  return getItemFromStorage("mb_privilege_level") === "owner";
}

function capitalize(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

function generateScheduleMap(input) {
  // Convert the input to an array
  const sessions = Object.values(input);

  // The output map
  const outputMap = {};

  // Iterate over the array to generate the map
  for (let duration = 1; duration <= sessions.length; duration++) {
    outputMap[duration] = {};
    for (let start = 0; start <= sessions.length - duration; start++) {
      outputMap[duration][start + 1] = `${sessions[start].start}-${
        sessions[start + duration - 1].end
      }`;
    }
  }

  return outputMap;
}

function determineClassTimes(badges, sessionTimes) {
  const classMap = generateScheduleMap(sessionTimes);
  badges.forEach((badge) => {
    badge.forEach((session) => {
      session.classTimes =
        classMap[session.classDurationInSessions][session.startingSession];
    });
  });
  return badges;
}
const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/**
 * Checks if the value of a given property in an array of objects is unique.
 *
 * @param {Object[]} arr - The array of objects to check.
 * @param {string} key - The property key to check for uniqueness.
 * @returns {boolean} Returns true if the property values are unique, false if a duplicate value is found.
 */
function isUnique(arr, key) {
  let tmpArr = [];
  for (let obj in arr) {
    if (tmpArr.indexOf(arr[obj][key]) < 0) {
      tmpArr.push(arr[obj][key]);
    } else {
      return false; // Duplicate value for property1 found
    }
  }
  return true; // No duplicate values found for property1
}

/**
 * Generates an array of numbers within a specified range.
 *
 * @param {number} start - The starting value of the range (inclusive).
 * @param {number} end - The ending value of the range (inclusive).
 * @returns {number[]} An array of numbers within the specified range.
 */
function rangeArray(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

/**
 * Checks if a given string is a valid URL.
 *
 * @param {string} urlString - The string to be checked as a URL.
 * @returns {boolean} Returns true if the string is a valid URL, false otherwise.
 */
const isValidUrl = (urlString) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

const isValidEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Sorts an array of class start times in ascending order.
 *
 * @param {string[]} times - An array of class start times in the format "HH:MMam/pm-HH:MMam/pm".
 * @returns {string[]} The sorted array of class start times.
 */
function sortClassStartTimes(times) {
  return times.sort((a, b) => {
    const get24hrTime = (time) => {
      let [hours, minutes] = time.split(/:|am|pm/).map((str) => parseInt(str));
      if (time.includes("pm") && hours < 12) hours += 12;
      if (time.includes("am") && hours === 12) hours -= 12;
      return hours * 60 + minutes;
    };

    const [startTimeA] = a.split("-");
    const [startTimeB] = b.split("-");

    return get24hrTime(startTimeA) - get24hrTime(startTimeB);
  });
}

/**
 * Formats a date string into "MM/DD/YYYY" format.
 *
 * @param {string} dateString - The date string to be formatted.
 * @returns {string} The formatted date string in "MM/DD/YYYY" format.
 */
function formatDate(dateString) {
  // Create a new Date object from the original date string
  const date = new Date(dateString);

  // Get the month, day, and year components from the Date object
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month as it's zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());

  // Format the date as "MM/DD/YYYY"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

/**
 * Calculates the registration status based on the open and event dates, ignoring the time of day.
 *
 * @param {string} openDate - The date when registration opens (in the format "September 9, 2023").
 * @param {string} closeDate - The date when registration opens (in the format "September 9, 2023").
 * @param {string} eventDate - The date of the event (in the format "September 15, 2023").
 * @returns {string} The registration status: "not-opened", "open", or "closed".
 */
const calculateRegistrationStatus = (openDate, closeDate, eventDate) => {
  const today = new Date(); // Get today's date

  const openDateTime = new Date(openDate);
  const closeDateTime = closeDate ? new Date(closeDate) : new Date(eventDate);
  const eventDateTime = new Date(eventDate);

  // Set the time to midnight for all dates
  today.setHours(0, 0, 0, 0);
  openDateTime.setHours(0, 0, 0, 0);
  eventDateTime.setHours(0, 0, 0, 0);
  closeDateTime.setHours(0, 0, 0, 0);

  if (
    today.getTime() === openDateTime.getTime() ||
    today.getTime() === eventDateTime.getTime() ||
    today.getTime() === closeDateTime.getTime()
  ) {
    return "open";
  } else if (today < openDateTime) {
    return "not-opened";
  } else if (today > eventDateTime || today > closeDateTime) {
    return "closed";
  } else {
    return "open";
  }
};

const getBreakpointValues = (xs, sm, md, lg, xl, def) => {
  // eslint-disable-next-line
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  // eslint-disable-next-line
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // eslint-disable-next-line
  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // eslint-disable-next-line
  const isLg = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  // eslint-disable-next-line
  const isXl = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  if (isXs) return xs;
  if (isSm) return sm;
  if (isMd) return md;
  if (isLg) return lg;
  if (isXl) return xl;
  return def;
};

function calculateAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

function calculateAgeOnDate(birthdateString, targetDateString) {
  const birthdate = new Date(birthdateString);
  const targetDate = new Date(targetDateString);

  let age = targetDate.getFullYear() - birthdate.getFullYear();
  const monthDiff = targetDate.getMonth() - birthdate.getMonth();
  const dayDiff = targetDate.getDate() - birthdate.getDate();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && dayDiff < 0) ||
    (monthDiff === 0 &&
      dayDiff === 0 &&
      targetDate.getHours() < birthdate.getHours())
  ) {
    age--;
  }
  return age;
}

function disableSwipe() {
  sessionStorage.setItem("disableSwipe", true);
}

function enableSwipe() {
  sessionStorage.setItem("disableSwipe", false);
}

function clearStorage() {
  // Loop through localStorage
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    if (key && key.startsWith("mb_")) {
      localStorage.removeItem(key);
    }
  }

  // Loop through sessionStorage
  for (let i = sessionStorage.length - 1; i >= 0; i--) {
    const key = sessionStorage.key(i);
    if (key && key.startsWith("mb_")) {
      sessionStorage.removeItem(key);
    }
  }
}

function generateRandomSixDigitInt() {
  // Generate a random float between 100000 and 999999
  const randomFloat = Math.random() * (999999 - 100000) + 100000;

  // Truncate the float to get an integer
  return Math.floor(randomFloat);
}

const webhookUrl = window._env_.REACT_APP_SLACK_WEBHOOK_URL;

function notifyViaSlackOnFailure(
  firstname,
  lastname,
  unitType,
  unitNumber,
  action,
  error,
) {
  // Format the message as a JSON string manually
  const message = JSON.stringify({
    text: `A failure occurred for *${firstname} ${lastname}* from ${unitType} ${unitNumber} on the UI. They were attempting to ${action} when they triggered the following error: ${error}`,
  });

  // Must use fetch for Slack webhook CORS error
  fetch(webhookUrl, {
    method: "POST",
    body: message,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .then((data) => {
      console.log("Message sent successfully:", data);
    })
    .catch((error) => {
      console.error("Failed to send message:", error);
    });
}

export {
  getItemFromStorage,
  isMobileDevice,
  isLoggedIn,
  capitalize,
  isCounselor,
  isAdmin,
  isOwner,
  determineClassTimes,
  usdFormatter,
  isUnique,
  rangeArray,
  isValidUrl,
  isValidEmail,
  sortClassStartTimes,
  formatDate,
  calculateRegistrationStatus,
  isSmallScreen,
  isSmallOrMobileDevice,
  getBreakpointValues,
  calculateAge,
  calculateAgeOnDate,
  disableSwipe,
  enableSwipe,
  clearStorage,
  generateRandomSixDigitInt,
  notifyViaSlackOnFailure,
};
