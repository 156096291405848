import {
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
  Typography,
  Icon,
  Backdrop,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PortraitIcon from "@mui/icons-material/Portrait";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizIcon from "@mui/icons-material/Quiz";
import EmailIcon from "@mui/icons-material/Email";
import IosShareIcon from "@mui/icons-material/IosShare";
import HomeIcon from "@mui/icons-material/Home";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

import {
  isLoggedIn,
  capitalize,
  isAdmin,
  isSmallOrMobileDevice,
  clearStorage,
  isOwner,
  isCounselor,
  getItemFromStorage,
} from "../../utils/utils";
import AnnouncementBanner from "../header/AnnouncementBanner";

const drawerWidth = 240;

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function Header(props) {
  const { datastore, headerOpen, setHeaderOpen, setHeaderHeight } = props;
  const navigate = useNavigate();
  const drawerRef = useRef(null);

  const [isBannerVisible, setBannerVisible] = React.useState(true);

  const headerRef = React.useRef(null);

  useEffect(() => {
    // Handler to call when your conditions are met
    function handleHeaderHeight() {
      if (!headerRef?.current?.clientHeight) {
        return;
      }
      setHeaderHeight(headerRef?.current?.clientHeight);
    }

    // Handler to call on window resize
    function handleResize() {
      handleHeaderHeight(); // Update the header height when window is resized
    }

    // Call handleHeaderHeight to set initial state
    handleHeaderHeight();

    // Subscribe to window resize events
    window.addEventListener("resize", handleResize);

    // Unsubscribe from events when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [headerRef, isBannerVisible]); // You can include any variables that affect your condition

  const handleDrawerOpen = () => {
    setHeaderOpen(true);
  };

  const handleDrawerClose = () => {
    setHeaderOpen(false);
  };

  const handleClickOutside = (event) => {
    // Ensure click is not inside drawer
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      handleDrawerClose();
    }
  };

  useEffect(() => {
    if (headerOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerOpen]);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar ref={headerRef}>
          <Toolbar>
            <div style={{ paddingLeft: 8 }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, boxShadow: 4 }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Typography
              variant={isSmallOrMobileDevice() ? "body1" : "h6"}
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              <strong>{datastore.config.eventName}</strong>
            </Typography>
            {isLoggedIn() ? (
              window.location.pathname !== "/scouts" &&
              window.location.pathname !== "/register" ? (
                isSmallOrMobileDevice() ? (
                  <Stack>
                    <div style={{ paddingTop: 10, paddingLeft: 16 }}>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                          navigate("/scouts");
                        }}
                        edge="start"
                        sx={{ boxShadow: 4 }}
                      >
                        <PortraitIcon />
                      </IconButton>
                    </div>
                    <Typography variant="caption" pb={1}>
                      Register
                    </Typography>
                  </Stack>
                ) : (
                  <Button
                    onClick={() => {
                      navigate("/scouts");
                    }}
                    color="primary"
                    variant="contained"
                  >
                    <PortraitIcon sx={{ paddingRight: 0.75 }} />
                    Register
                  </Button>
                )
              ) : null
            ) : isSmallOrMobileDevice() ? (
              <Stack>
                <div style={{ paddingTop: 10, paddingLeft: 6 }}>
                  <IconButton
                    size="small"
                    color="inherit"
                    aria-label="login"
                    onClick={() => {
                      navigate("/login");
                    }}
                    edge="start"
                    sx={{ boxShadow: 4 }}
                  >
                    <LoginIcon size="small" />
                  </IconButton>
                </div>
                <Typography variant="caption" pb={1}>
                  Sign in
                </Typography>
              </Stack>
            ) : (
              <Button
                onClick={() => {
                  navigate("/login");
                }}
                color="primary"
                variant="contained"
              >
                <LoginIcon sx={{ paddingRight: 0.75 }} />
                Sign In
              </Button>
            )}
          </Toolbar>
          {isBannerVisible &&
            localStorage.getItem("mb_announcement_closed") !== "true" &&
            window.location.href.endsWith("/login") && (
              <AnnouncementBanner
                // message={datastore.config.announcementMessage}
                message="If you joined us last year, you can use the same account to login. Please reset your password if you can't remember your login. Thank you!"
                onClose={() => {
                  console.log("Closed");
                  setBannerVisible(false);
                  localStorage.setItem("mb_announcement_closed", "true");
                }}
              />
            )}
          {isBannerVisible &&
            (window.location.href.endsWith("/register") ||
              window.location.href.endsWith("/edit-registration")) &&
            window.location.href.includes("mbcollege") && (
              <AnnouncementBanner
                // message={datastore.config.announcementMessage}
                message="The prerequisites listed are from our last Merit Badge College. Please check back later for any updated prerequisites."
                onClose={() => {
                  console.log("Closed");
                  setBannerVisible(false);
                  localStorage.setItem("mb_announcement_closed", "true");
                }}
              />
            )}
        </AppBar>
      </ElevationScroll>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={headerOpen}
        ref={drawerRef}
      >
        <DrawerHeader>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <strong>
              {isLoggedIn()
                ? "Hello, " +
                  capitalize(getItemFromStorage("mb_first_name")) +
                  "!"
                : "Welcome!"}
            </strong>
          </Typography>
          <IconButton
            onClick={handleDrawerClose}
            size="small"
            sx={{ boxShadow: 4 }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {!isLoggedIn() && window.location.pathname !== "/login" ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate("/login");
                }}
              >
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Sign In" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {window.location.pathname !== "/" ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate("/");
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {isLoggedIn() && window.location.pathname !== "/scouts" ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate("/scouts");
                }}
              >
                <ListItemIcon>
                  <Icon>
                    <img src="./ScoutSignIcon.svg" alt="Scout Sign" />
                  </Icon>
                </ListItemIcon>
                <ListItemText primary="My Scouts" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {window.location.pathname !== "/faq" ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate("/faq");
                }}
              >
                <ListItemIcon>
                  <QuizIcon />
                </ListItemIcon>
                <ListItemText primary="FAQ" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {window.location.pathname !== "/contact" ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate("/contact");
                }}
              >
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {window.location.pathname !== "/flyer" &&
          datastore.config.flyerFileId !== null ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate("/flyer");
                }}
              >
                <ListItemIcon>
                  <IosShareIcon />
                </ListItemIcon>
                <ListItemText primary="Flyer" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {window.location.pathname !== "/admin" &&
          (isAdmin() || isOwner() || isCounselor()) ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  navigate("/admin");
                }}
              >
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Administration" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {isLoggedIn() ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleDrawerClose();
                  clearStorage();
                  navigate("/login");
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Drawer>
      <Backdrop
        sx={{ color: "#fff", zIndex: "1100 !important" }}
        open={headerOpen}
      />
    </>
  );
}
